export const educationData = [
  {
    id: 1,
    institution: "SMUDE",
    course: "MBA in IT",
    startYear: "2013",
    endYear: "2015",
  },
  {
    id: 2,
    institution: "DAV Institute of Engineering and Technology",
    course: "Bachelor Degree (B.TECH)",
    startYear: "2010",
    endYear: "2013",
  },
  {
    id: 3,
    institution: "Sant Longowal Institute of Engineering and Technology",
    course: "Diploma in Computer Science",
    startYear: "2006",
    endYear: "2010",
  },
 
];
