export const experienceData = [
  // {
  //   id: 1,
  //   company: "Kokozi",
  //   jobtitle: "Full Stack Developer",
  //   startYear: "2021",
  //   endYear: "Present",
  // },
  // {
  //   id: 2,
  //   company: "Boffin Coders Pvt. Ltd.",
  //   jobtitle: "Director/Founder",
  //   startYear: "2017",
  //   endYear: "Present",
  // },
  {
    id: 1,
    company: "Hooaah",
    jobtitle: "Freelance Web Developer",
    startYear: "June 2023",
    endYear: "August 2024",
  },
  {
    id: 1,
    company: "Proper X",
    jobtitle: "Freelance Web Developer",
    startYear: "Jan 2023",
    endYear: "June 2023",
  },
  {
    id: 1,
    company: "Kokozi",
    jobtitle: "Freelance Web Developer",
    startYear: "Jan 2022",
    endYear: "Jan 2023",
  },
  {
    id: 1,
    company: "Nook",
    jobtitle: "Freelance Web Developer",
    startYear: "Feb 2021",
    endYear: "Dec 2021",
  },
  {
    id: 2,
    company: "EKG Derema",
    jobtitle: "Freelance Web Developer",
    startYear: "Jun 2017",
    endYear: "Jan 2021",
  },
  {
    id: 3,
    company: "Offbeat Software Solutions Pvt. Ltd.",
    jobtitle: "Software Developer",
    startYear: "Jan 2013",
    endYear: "June 2017",
  },
];
