import profilepic from "../assets/png/profilepic.jpg";
export const headerData = {
  name: "Manoj Sethi",
  title: "Hello! I am",
  desciption:"Full Stack Developer with over 12 years of experience in JavaScript, React, Node.js, Redux, .NET, C#, and TypeScript. I have led the development of numerous research projects and have built applications handling millions of users. My strong logical and technical skills, combined with my ability to quickly adapt to new technologies, enable me to tackle complex problems efficiently. I am seeking a full-time role where I can confront new challenges and utilize my interpersonal skills, time management, and problem-solving abilities. I am committed to achieving ambitious development goals on tight schedules, delivering high-quality code, and managing multiple projects with precision and attention to detail.",
  image: profilepic,
  imagebw: "https://i.ibb.co/7GJRmGZ/IMG-20220214-121913.jpg",
  resumePdf:
    "https://drive.google.com/file/d/1CdOO-rrviee0HRJwX-lBpsbYDOHfJro3/view?usp=sharing",
};
