import bloodlogo from "../assets/png/blood-logo.webp";
import ekgderema from "../assets/png/ekgderema.png";
import hooaah from "../assets/png/hooaah.png";
import kokozi from "../assets/png/kokozi.png";
import nook from "../assets/png/nook.png";
import properFans from "../assets/png/proper-fans.webp";
import researchLab from "../assets/png/research-lab.png";
import timeZones from "../assets/png/timezones.png";
import blackdog from "../assets/svg/social/blackdog.svg";
export const projectsData = [
  {
    id: 1,
    projectName: "Hooah",
    projectDesc:
      "Hooaah is an eCommerce marketplace focused on food and beverage products. It allows buyers and sellers to create accounts, buy and sell products, and share stories. The platform is built with Next.js for the frontend, Nest.js for the backend, and MongoDB for the database. All activities are managed through an admin panel.",
    tags: ["NextJS", "NestJS", "GraphQL", "TypeScript", "MongoDB"],
    code: "",
    demo: "https://hooaah.io/",
    image: hooaah,
  },
  {
    id: 1,
    projectName: "TimeZones",
    projectDesc:"This project is to see the time of different timezones at a single place. User can also drag a slider to see what will be the equivalent time in other timezones.",    tags: ["Node", "NestJS", "ExpressJS", "TypeScript", "MongoDB"],
    code: "",
    demo: "https://timezones.in/",
    image: timeZones,
  },
  {
    id: 1,
    projectName: "Proper X (Norway)",
    projectDesc:
      "Project was related to social median application designed for creators. Creators can create plans and fans can get connected with them via different modes of communication.",
    tags: ["Firebase", "Node JS", "TypeScript", "MongoDB","WebSockets","Agora","Redis","Redis JSON","AWS S3","Github Actions"],
    code: "",
    demo: "",
    image: properFans,
  },
  {
    id: 1,
    projectName: "Research Lab",
    projectDesc:
      "Project was related to social median application designed for creators. Creators can create plans and fans can get connected with them via different modes of communication.",
      tags: ["NextJS", "Node JS", "TypeScript", "MongoDB","AWS S3"],
      code: "",
    demo: "https://researchlab.manojsethi.com/",
    image: researchLab,
  },
  {
    id: 1,
    projectName: "Kokozi",
    projectDesc:
      "I am working with Kokozi. Kokozi specializes in the new content ecosystem with an audio content media platform service, enabling  kids to learn and engage with new content.",
    tags: ["Node", "NestJS", "ExpressJS", "TypeScript", "MongoDB"],
    code: "",
    demo: "",
    image: kokozi,
  },

  {
    id: 3,
    projectName: "Black Dog",
    projectDesc:
      "Research into mental health and suicide prevention is at the heart of everything we do. Our innovative trials and strong collaborations are providing solutions for the whole population.",
    tags: [".NET 4", "WebForms"],
    code: "",
    demo: "",
    image: blackdog,
  },
  {
    id: 4,
    projectName: "EKG Derema",
    projectDesc:
      "This was an application that was developed for the warehouse reports connected with Amazon Marketplace to know the various ups/downs and help them make better decisions for increasing lead",
    tags: ["NodeJS", "ReactJS", "MongoDB."],
    code: "",
    demo: "",
    image: ekgderema,
    innerWidth: "1rem",
  },
  {
    id: 5,
    projectName: "ISaveLife",
    projectDesc:
      "I Save a Life is a blood donation app. By giving blood, you become a vital lifeline in emergencies and a source of hope for those needing long-term treatments. Countless lives have been saved thanks to the selfless contributions of blood donors like you. ",
    tags: ["NodeJS", "NextJS", "MongoDB ."],
    code: "",
    demo: "",
    image: bloodlogo,
    innerWidth: "1rem",
  },
  {
    id: 6,
    projectName: "NOOK",
    projectDesc:
      "I worked with Nook as a full-stack developer. It's part of the fintech industry. Nook is on a mission to connect the underserved to genuine lenders. We have partnered with the Philippines' biggest banks to simplify loans. Fair finance for everyone is our ultimate goal. ",
    tags: ["NodeJS", "ReactJS", "Angular", "AWS ."],
    code: "",
    demo: "",
    image: nook,
    innerWidth: "1rem",
  },
];
