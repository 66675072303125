/* eslint-disable */
import { contactsGreen } from "./images";
import { contactsYellow } from "./images";

export const theLightTheme = {
  type: "light",
//   primary: "#1D9BF0",
  primary: "#e2a61d",
  quaternary: "#EFF3F4",
  quaternaryLight: "#F7F9F9",
  secondary: "#ffffff",
  tertiary: "#0f1419",
  buttonColor: "#0F1419",
  contactsimg: contactsYellow,
};

export const theDarkTheme = {
  type: "dark",
//   primary: "#1D9BF0",
  primary: "#e2a61d",
  quaternary: "#1E2732",
  quaternaryLight: "#8b98a5",
  secondary: "#15202B",
  tertiary: "#EFF3F4",
  buttonColor: "#8B98A5",
  contactsimg: contactsYellow,
};

// export const theDarkTheme = {
//     type: "dark",
//     primary: "#24eae3",
//     quaternary: "#1E2732",
//     quaternaryLight: "#8b98a5",
//     secondary: "#15202B",
//     tertiary: "#EFF3F4",
//     buttonColor: "#8B98A5",
//     contactsimg: contactsGreen,
//   };
  
